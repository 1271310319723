<template>
	<div>
		<el-card>
			<el-row>
				<el-button type="primary" @click="addLevel()">新增</el-button>
			</el-row>
			<!-- 代理商表单 -->
			<el-table :data="levelData" border style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="wx_id" label="ID"></el-table-column>
				<el-table-column prop="nickName" label="微信昵称"></el-table-column>
				<el-table-column label="微信头像">
					<template slot-scope="scope">
						<div>
							<el-avatar shape="square" size="large" :src="scope.row.avatarUrl"></el-avatar>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="phoneNumber" label="手机号"></el-table-column>
				<el-table-column prop="createtime" label="加入时间"></el-table-column>
				<el-table-column fixed="right" label="操作" width="240">
					<template slot-scope="scope" v-if="scope.row.phoneNumber">
						<el-button type="primary" v-if="scope.row.is_agent==0" plain size="small" @click="setAgent(scope.row)">设为代理人</el-button>
						<el-button type="danger" v-else plain size="small" @click="goJump('/agent_add')">已为代理人</el-button>
						<el-button type="primary" v-if="scope.row.is_jingli==0" plain size="small" @click="setJingli(scope.row)">设为经理人</el-button>
						<el-button type="danger" v-else plain size="small" @click="goJump('/jingli_list')">已为经理人</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 设为代理人 -->
			<el-dialog title="设为代理人" width="60%" :visible.sync="addLevelDialog" :modal-append-to-body="false">
				<el-form ref="addlevelForm" :model="addlevelForm" label-width="120px" :rules="addlevelRules">
					<el-row>
					  	<el-col :span="12">
							<el-form-item label="真实名称" prop="real_name">
								<el-input v-model="addlevelForm.real_name" maxlength="10" placeholder="请输入真实姓名" :disabled="user_info.real_name?'disabled':false"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="服务区域" prop="city_id" style="text-align: left;">
								<treeselect v-model="addlevelForm.city_id" :options="city_list" :normalizer="normalizer" :defaultExpandLevel="Infinity" :show-count="true" placeholder="请选择服务区域" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="手机号" prop="phoneNumber">
								<el-input v-model.number="addlevelForm.phoneNumber" placeholder="请输入手机号" disabled="disabled"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="上级代理" prop="parent_id" style="text-align: left;">
								<el-select v-model="addlevelForm.parent_id" clearable filterable remote reserve-keyword placeholder="输入名称/手机号进行搜索"
										:remote-method="remoteMethod" :loading="loading">
								    <el-option
								      v-for="(item,key) in options"
								      :key="item.agent_id"
								      :label="item.real_name"
								      :value="item.agent_id">
								    </el-option>
							  	</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="账号" prop="account">
								<el-input v-model="addlevelForm.account" maxlength="10" placeholder="请输入账号" :disabled="user_info.account?'disabled':false"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="密码" prop="password">
								<el-input v-model="addlevelForm.password" placeholder="请输入密码" :disabled="user_info.password?'disabled':false" style="width:220px"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="第二年分佣配比" prop="fypb_two" style="text-align: left;">
								<el-input v-model.number="addlevelForm.fypb_two" placeholder="请输入第二年分佣配比" style="width: 220px"></el-input>
								<span>%</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="第三年分佣配比" prop="fypb_three">
								<el-input v-model.number="addlevelForm.fypb_three" placeholder="请输入第三年分佣配比" style="width: 220px"></el-input>
								<span>%</span>
							</el-form-item>
						</el-col>
						<el-col :span="24" style="text-align:center">
						  	<el-button type="default" @click="addLevelDialog=false">取消</el-button>
							<el-button type="primary" @click="agentSubmit">确定</el-button>
					  	</el-col>
					</el-row>
				</el-form>
			</el-dialog>
			
			<!-- 设为经理人 -->
			<el-dialog title="设为经理人" width="60%" :visible.sync="addJingliDialog" :modal-append-to-body="false">
				<el-form ref="addJingliForm" :model="addJingliForm" label-width="120px" :rules="addJingliRules">
					<el-row>
					  	<el-col :span="12">
							<el-form-item label="真实名称" prop="real_name">
								<el-input v-model="addJingliForm.real_name" maxlength="10" placeholder="请输入真实姓名" :disabled="user_info.real_name?'disabled':false"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="服务区域" prop="city_id" style="text-align: left;">
								<treeselect v-model="addJingliForm.city_id" :options="city_list" :normalizer="normalizer" :defaultExpandLevel="Infinity" :show-count="true" placeholder="请选择服务区域" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="账号" prop="account">
								<el-input v-model="addJingliForm.account" maxlength="10" placeholder="请输入账号" :disabled="user_info.account?'disabled':false"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="密码" prop="password">
								<el-input v-model="addJingliForm.password" placeholder="请输入密码" :disabled="user_info.password?'disabled':false" style="width:220px"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="手机号" prop="phoneNumber">
								<el-input v-model.number="addJingliForm.phoneNumber" placeholder="请输入手机号" disabled="disabled"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24" style="text-align:center">
						  	<el-button type="default" @click="addJingliDialog=false">取消</el-button>
							<el-button type="primary" @click="jingliSubmit">确定</el-button>
					  	</el-col>
					</el-row>
				</el-form>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Treeselect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Treeselect,Pagination
		},
		data() {
			return {
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				searchText: "",
				phoneText: "",
				datePicker: "",
				options: [],
				addLevelDialog: false,
				total: 0,
				addlevelForm: {
					wx_id: 0,
					real_name: '',
					account: '',
					password: '',
					parent_id: '',
					fypb_two: '',
					fypb_three: '',
					city_id: null,
					phoneNumber: '',
				},
				addlevelRules: {
					real_name: [{
						required: true,
						message: "请输入真实名称",
						trigger: "blur"
					}, ],
					account: [{
						required: true,
						message: "请输入账号",
						trigger: "blur"
					}, ],
					city_id: [{
						required: true,
						message: "请选择服务区域",
						trigger: "blur"
					}, ],
				},
				levelData: [],
				user_info: {},
				addJingliDialog: false,
				addJingliForm: {
					wx_id: 0,
					real_name: '',
					account: '',
					password: '',
					parent_id: '',
					city_id: null,
					phoneNumber: '',
					agent_type: 2,
				},
				addJingliRules: {
					real_name: [{
						required: true,
						message: "请输入真实名称",
						trigger: "blur"
					}, ],
					account: [{
						required: true,
						message: "请输入账号",
						trigger: "blur"
					}, ],
					city_id: [{
						required: true,
						message: "请选择服务区域",
						trigger: "blur"
					}, ],
				},
				loading: false,
				city_list: [],
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getCityList();
				this.getUserList();
			}
		},
		methods: {
			getCityList(){
	  		var url = 'city/city_list';
				let params = {
					is_del: 1
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.city_list = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
	  	},
			remoteMethod(query) {
				if (query !== '') {
					that.loading = true;
					setTimeout(() => {
            that.loading = false;
            var url = 'agent/parent_agent';
						let params = {
							agent_name: query,
							agent_id: that.addlevelForm.agent_id,
						};
						that.fd_post(url, params).then((res) => {
							if(res.status){
								that.options = res.list;
								console.log(res.list);
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 200);
				} else {
					this.options = [];
				}
			},
			getUserList() {
				let that = this;
				var url = 'custom/user_list';
				let params = {
					user_name: this.searchText,
					phone: this.phoneText,
					date_time: this.datePicker,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.levelData = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//转换菜单数据结构
			normalizer(node) {
				if(node.children && !node.children.length) {
					delete node.children;
				}
				return {
					id: node.city_id,
					label: node.city_name,
					children: node.children,
				};
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getUserList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getUserList();
			},
			//设为代理人
			setAgent(item) {
				this.user_info = item;
				this.addlevelForm.wx_id = item.wx_id;
				this.addlevelForm.real_name = item.real_name;
				this.addlevelForm.account = item.account;
				this.addlevelForm.phoneNumber = item.phoneNumber;
				this.addLevelDialog = true;
			},
			agentSubmit() {
				this.$refs.addlevelForm.validate(valid => {
					if(!valid) {
						return;
					} else {
						if(timer != null) {
							clearTimeout(timer);
						}
						timer = setTimeout(() => {
							var url = 'agent/addOrUpdate';
							let params = that.addlevelForm;
							that.fd_post(url, params).then((res) => {
								if(res.status) {
									that.addLevelDialog = false;
									that.$refs.addlevelForm.resetFields();
									that.$message.success('操作成功');
									that.getUserList();
								} else {
									that.$message.error(res.msg);
								}
							}).catch((err) => {
								that.$message.error('网络错误');
							});
						}, 1000);
					}
				});
			},
			goJump(jump_url) {
				this.$router.push({
					path: jump_url,
					query: {}
				})
			},
			//设为经理人
			setJingli(item) {
				this.user_info = item;
				this.addJingliForm.wx_id = item.wx_id;
				this.addJingliForm.real_name = item.real_name;
				this.addJingliForm.account = item.account;
				this.addJingliForm.phoneNumber = item.phoneNumber;
				this.addJingliDialog = true;
			},
			jingliSubmit() {
				this.$refs.addJingliForm.validate(valid => {
					if(!valid) {
						return;
					} else {
						if(timer != null) {
							clearTimeout(timer);
						}
						timer = setTimeout(() => {
							var url = 'agent/addOrUpdate';
							let params = that.addJingliForm;
							that.fd_post(url, params).then((res) => {
								if(res.status) {
									that.addJingliDialog = false;
									that.$refs.addJingliForm.resetFields();
									that.$message.success('操作成功');
									that.getUserList();
								} else {
									that.$message.error(res.msg);
								}
							}).catch((err) => {
								that.$message.error('网络错误');
							});
						}, 1000);
					}
				});
			},
		},
	}
</script>

<style lang="scss" scoped>
	.el-row {
		text-align: left;
		margin-bottom: 20px;
	}
	.mr3{
	  margin-right: 5px;
	}
</style>